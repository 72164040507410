import {
  EAccountPaymentMethodStatus,
  EAccountStatus,
  EAccountTiers,
  EFeature,
} from '../../enum';
import { ISubscription } from './subscription';

export interface IUtm {
  source?: string;
  medium?: string;
  campaign?: string;
}

export interface IAccountAddress {
  address1?: string;
  address2?: string;
  city?: string;
  country?: string;
  postcode?: string;
  state?: string;
}

export interface IRetargeting {
  emailCount: number;
  lastEmailSentAt: Date;
}

export interface IAccount {
  address: IAccountAddress;
  country?: string;
  industry?: string;
  createdAt: Date;
  id: string;
  name: string;
  phone?: string;
  reference: string;
  transferVerification?: string;
  status: EAccountStatus;
  utm?: IUtm;
  tier: EAccountTiers;
  settings?: IAccountSetting;
  paymentMethodStatus?: EAccountPaymentMethodStatus;
  offerId?: string;
  updatedAt: Date;
  updatedBy?: string;
  deletedAt?: Date;
  deletedBy?: string;
  retargeting?: IRetargeting;
}

export interface ISearchableAccount {
  id: string;
  createdAt: string;
  name: string;
  phone?: string;
  reference: string;
  status: string;
  tier: string;
  updatedAt: string;
  updatedBy?: string;
  usage: {
    connectQrs: number;
    shortUrls: number;
    aiQrs: number;
    users: number;
    forms: number;
    pages: number;
  };
  version: number;
}

export interface IAccountApi extends IAccount {
  subscription?: ISubscription;
}

export interface IAccountSetting {
  monthlyFinancialReport: boolean;
  features: IAccountSettingFeature;
}

export const AccountSettingFeatures = [
  EFeature.MOTIFMICRO,
  EFeature.TRACER,
  EFeature.TRACK_AND_TRACE,
  EFeature.PROTECTCODE,
  EFeature.PROTECTCODE_PLUS,
  EFeature.BRAND_PROTECTION,
  EFeature.CONNECT_QR,
] as const;
export type TAccountSettingFeature = (typeof AccountSettingFeatures)[number];

export type IAccountSettingFeature = Record<TAccountSettingFeature, boolean>;

export interface IAccountDeleteQueueEvent {
  accountId: string;
  owner: {
    name: string;
    email: string;
  };
  dueTime: string;
}

export interface ITransferAccountToken {
  sub: string;
  transferTo: string;
  transferFrom: string;
  tokenType: 'transfer_account';
  iat?: string;
  exp?: string;
}

export interface ITransferAccountEvent {
  accountId: string;
  transferTo: string;
  transferFrom: string;
}
