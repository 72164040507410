export enum EDimensions {
  BROWSER = 'browser',
  CAMPAIGN_SCANS = 'campaign',
  CITY = 'city',
  COUNTRY = 'country',
  DEVICE_BRAND = 'deviceBrand',
  DEVICE_MODEL = 'deviceModel',
  OS = 'os',
  PRODUCT_SCANS = 'product',
  AUTH_STATE = 'authState',
}

export enum EScanStats {
  AUTH_STATE = 'authState',
  COUNTRIES = 'countries',
  FAILURE_REASON = 'failureReason',
  SCANS = 'scans',
  USERS = 'users',
}

export enum EMetrics {
  VISITORS = 'visitors',
  COUNTRIES = 'countries',
  CITIES = 'cities',
  BROWSERS = 'browsers',
  OS = 'os',
  DEVICE_BRANDS = 'deviceBrands',
  DEVICE_MODELS = 'deviceModels',
}

export enum EMetricsV3 {
  VISITORS = 'visitors',
  COUNTRIES = 'countries',
  CITIES = 'cities',
  BROWSERS = 'browsers',
  OS = 'os',
  DEVICE_BRANDS = 'deviceBrands',
  DEVICE_MODELS = 'deviceModels',
  SCANS = 'scans',
  AUTHED = 'authed',
  FAILED_AUTH = 'failedAuth',
  NOT_AUTHED = 'notAuthed',
  INVALID_PRODUCT = 'invalidProduct',
  INVALID_PROTECT_CODE = 'invalidProtectCode',
}

export enum ETimeSeriesLines {
  AUTHED = 'authed',
  FAILED_AUTH = 'failedAuth',
  NOT_AUTHED = 'notAuthed',
  SCANS = 'scans',
  INVALID_PRODUCT = 'invalidProduct',
  INVALID_PROTECT_CODE = 'invalidProtectCode',
  TRACER_NOT_DETECTED = 'tracerNotDetected',
}
